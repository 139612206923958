import { Controller } from "@hotwired/stimulus"
import { Crisp } from "crisp-sdk-web"
import 'vanilla-cookieconsent/dist/cookieconsent.js'

export default class extends Controller {
  static values = {
    apiKey: String
  }
  connect() {
    const cc = initCookieConsent();

    if(this.apiKeyValue == undefined || this.apiKeyValue.length == 0) {
      return
    }

    Crisp.configure(this.apiKeyValue);

    // If user authorized analytics cookies
    if(cc.allowedCategory('analytics')) {
      if(window.userServices.email.length) {
        Crisp.user.setEmail(window.userServices.email)
      }

      if(window.userServices.company.length) {
        Crisp.user.setCompany(window.userServices.company)
      }

      if(window.userServices.name.length) {
        Crisp.user.setNickname(window.userServices.name)
      }


      // This works
      //
      // Crisp.session.pushEvent("purchase", {
      //   price: 66.66,
      //   item: "XXXX_XXXX"
      // });

      window.$crisp.push(["set", "session:data", [[
        ["plan", window.userServices.plan],
        ["subscribed", window.userServices.subscribed]
      ]]]);

    }

    // Check if the URL includes the parameter session_logout to reset the session
    const url = new URL(window.location.href)
    if (url.searchParams.get('session_logout') !== null && window.$crisp) {
      window.$crisp.push("session:reset");
    }
  }
}
