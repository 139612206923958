import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["wrapper", "messages"];

  initialize() {
    this.observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.debouncedScrollToBottom();
        }
      }
    });

    this.debouncedScrollToBottom = debounce(this.scrollToBottom, 500, {
      leading: true,
      trailing: true
    });
  }

  connect(){
    const config = { attributes: true, childList: true, subtree: true };

    this.observer.observe(this.messagesTarget, config);
  }

  disconnect() {
    this.observer.disconnect();
  }

  scrollToBottom() {
    this.wrapperTarget.scrollTop = this.wrapperTarget.scrollHeight;
  }
}
