import { Controller } from "@hotwired/stimulus";
import { toJSON } from "gobierto-vizzs";
import { parseDataTypes, getDateRange, INITIAL_AMOUNT_RANGE_VALUES, NUMBER_OF_PROPOSALS } from "../../helpers/vizzs_report";

/**
 * Shared functions for visualizations
 */
export default class extends Controller {
  static targets = [
    "chart",
    "typeSelector",
    "dateSelector",
    "ratioSelector"
  ];

  static values = {
    csvEndpoint: String
  };

  async initialize() {
    // selectors default values
    this.currentType = this.getType()
    this.currentDate = this.getDate()
    this.currentRatio = this.getRatio()
    this.DEFAULT_COUNT_PROPERTY = "initial_amount_no_taxes"

    if (this.hasCsvEndpointValue) {
      const response = await fetch(this.csvEndpointValue).then((r) => r.text());
      const data = this.csvEndpointValue ? toJSON(response) : response;

      // Simulates same call as events do
      this.init({ detail: data })
    }
  }

  init({ detail: data }) {
    this.initialData = parseDataTypes(data)

    // Extract this block from the main execution thread to avoid blocking the DOM
    // Technical debt: Use a worker
    setTimeout(() => {
      const [chart, parsedData] = this._loadChart(this.chartTarget, this.initialData)
      this.visualization = chart
      this.visualization.setData(parsedData)
    }, 0);
  }

  getType() {
    if (!this.hasTypeSelectorTarget) return
    return this.typeSelectorTarget.value
  }

  getDate() {
    if (!this.hasDateSelectorTarget) return
    return this.dateSelectorTargets.find(x => !!x.ariaChecked)?.value || this.dateSelectorTargets[0]
  }
  getRatio() {
    if (!this.hasRatioSelectorTarget) return
    return this.ratioSelectorTargets.find(x => !!x.ariaChecked)?.value || this.ratioSelectorTargets[0]
  }

  _helper(data, { typeSelector = this.currentType, dateSelector = this.currentDate, ratioSelector = this.currentRatio }) {
    // all possible date values to fulfil the X-axis
    const dates = getDateRange(dateSelector)
    // pass the maximum allowed date to filter the data displayed
    const parsedData = this._parseData(data, { typeSelector, dateSelector, oldestAllowedDate: new Date(dates[0]) })
    // set the categories manually
    const categories = [...new Set([...parsedData.map((d) => d[dateSelector]), ...dates])].sort();
    // what categories should show a tick in the axis
    const tickValues = categories.filter(x => !(["month", "quarter"].includes(this.currentDate) && !x.includes("-01")));
    // what typeSelectors should be ordered
    const sortStack = ["process_type", "gobierto_contractor_entity_type", "contract_type"].includes(typeSelector)
    // what property should aggregate values
    const countProp = ratioSelector === "count" ? this.DEFAULT_COUNT_PROPERTY : null

    // what series should have a different order
    const series = {
      "initial_amount_range": Object.values(INITIAL_AMOUNT_RANGE_VALUES),
      "number_of_proposals": Object.values(NUMBER_OF_PROPOSALS).map(x => x[document.documentElement.lang])
    }

    return {
      parsedData,
      categories,
      series: series[typeSelector],
      tickValues,
      countProp,
      sortStack
    }
  }

  _loadChart() {}
}
